import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'

export default {
  async getYearWeekIdToday() {
    const response = await axios.get('uni/year-week/get-year-week-id-by-today')
    const { data } = response
    if (data.code === ApiCode.SUCCESS) {
      return data.data
    }
    return null
  },

}
